<template>
  <div>
    <NavigationSubNav main-route="data" :title="actionName" />
    <div class="container-fluid pb-1">
      <Loader v-if="showLoader" :description="loadingPrompt" :title="loadingTitle" />

      <div v-else class="mb-sm-4 mx-3 my-4 row" style="top: 75px">
        <div class="col-lg-12">
          <div class="border">
            <div class="row p-4">
              <div class="col">
                <h5 class="h5 text-muted font-weight-bold">{{ title }}</h5>
                <p>{{ message }}</p>
              </div>
              <div>
                <b-dropdown
                  id="dropdown-1"
                  text="Share this Page"
                  class="shadow float-right"
                  variant="primary"
                  :disabled="editting.length == 0"
                  block
                >
                  <b-dropdown-item>Share via Link</b-dropdown-item>
                  <b-dropdown-item>Share to user </b-dropdown-item>
                </b-dropdown>
                <button
                  :disabled="editting.length == 0"
                  class="btn mr-2 float-right btn-warning shadow"
                >
                  Advance Filters
                </button>
              </div>
            </div>

            <div class="row pt-3 px-3 py-3">
              <div class="col-lg-2 mb-1">
                <span v-if="edittingEnabled">{{ tableDescription }}</span>
              </div>

              <div v-for="entry in filters" :key="entry.name" class="float-right col">
                <el-select
                  v-model="tableFilters[entry.name]"
                  clearable
                  filterable
                  :placeholder="entry.label"
                  class="mr-2 row"
                >
                  <el-option
                    v-for="option in entry.options"
                    :key="option"
                    :value="option"
                    :label="option"
                  >
                  </el-option>
                </el-select>
              </div>

              <div class="col-lg-2 mb-1">
                <button
                  :disabled="editting.length == 0"
                  class="btn option-btn btn-block btn-warning"
                  @click.prevent="handleDeleteSelectedCells"
                >
                  Delete <b-icon icon="trash-fill" aria-hidden="true"></b-icon>
                  <small v-if="selectedRows.length > 0" class="tab__badge text-white">{{
                    selectedRows.length
                  }}</small>
                </button>
              </div>
            </div>

            <TableComponent
              :fields="fields"
              bordered
              :data="preview"
              :loading="tableLoading"
              :empty-text="emptyText"
              @sizeChange="handlePageSizeChange"
              @selected="handleSelection"
            />

            <div class="row mb-3 p-4 pt-3 px-3 py-3">
              <div class="col-lg-12">
                <button
                  :disabled="editting.length == 0"
                  class="btn mr-2 action-btn btn-danger shadow"
                  @click.prevent="cancel"
                >
                  Cancel
                </button>
                <button
                  :disabled="editting.length == 0"
                  class="btn mr-2 action-btn btn-primary shadow"
                  @click="save"
                >
                  Save
                </button>
                <button
                  :disabled="editting.length == 0"
                  class="btn mr-2 action-btn float-right btn-success shadow"
                  style="width: 220px"
                  @click="proceed"
                >
                  Update Dashboard
                  <b-icon icon="arrow-repeat"></b-icon>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- shows a prompt , properties are defined in Mixin Object -->
    <ProgressPrompt
      :visible="isProgresPromptVisible"
      :cancel="promptCancel"
      :confirm="promptConfirm"
      :message="promptMessage"
      :title="promptTitle"
      :cancel-text="promptCancelText"
      :cancel-type="promptCancelClass"
      :confirm-text="promptConfirmText"
      :confirm-type="promptConfirmClass"
      @close="onPromptClose"
    />

    <!-- Show successfull Push prompt -->
    <el-dialog
      :visible.sync="isConfirmationDialogVisible"
      width="24%"
      top="20vh"
      height="80vh"
      center
    >
      <div>
        <div class="row dialog-content">
          <div class="col pb-4">
            <b-iconstack font-scale="5">
              <b-icon stacked icon="circle-fill" variant="success"></b-icon>
              <b-icon stacked icon="check" scale="1" variant="white"></b-icon>
            </b-iconstack>
          </div>
        </div>

        <div class="row dialog-content">
          <div class="col mb-4">
            <span class="h5">MSDAT Dashboard</span><br />
            <span class="h5">Successfully Updated!</span>
          </div>
        </div>

        <div class="row dialog-content">
          <div class="col mb-4">
            <span>Today, {{ getDate() }}</span>
          </div>
        </div>

        <div class="row dialog-content">
          <div class="col pb-4">
            <span> {{ fileName }} data uploaded,</span> <br />
            <span> cleaned and meta-data reviewed</span>
          </div>
        </div>

        <div class="row dialog-content">
          <div class="col pb-4">
            <b-button variant="success">Launch Dashboard</b-button>
          </div>
        </div>
        <div class="row dialog-content">
          <div class="col pb-4">
            <b-button variant="light">See Update History</b-button>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
/**
 * @typedef {import("../../store/modules/data.old").Cell} Cell
 */

import NavigationSubNav from "../../components/NavigationSubNav.vue";
import Loader from "../../components/Loader.vue";
import TableComponent from "../../components/Table.vue";
import DataMixin from "./mixin";
import { mapGetters } from "vuex";
import { DateTime } from "luxon";

import UploadResource from "../../api/fileResource";
const uploadResource = new UploadResource();

export default {
  name: "PreviewEditData",
  components: {
    NavigationSubNav,
    Loader,
    TableComponent,
  },
  mixins: [DataMixin],
  data() {
    return {
      isConfirmationDialogVisible: false,
    };
  },
  computed: {
    ...mapGetters({
      hasPassAllChecks: "data/dbDuplicateCheckState",
    }),
    fileName() {
      if (this.currentRecord) {
        return this.currentRecord.name;
      }
      return "Survey Report";
    },
    loadingTitle() {
      return "Performing final checks";
    },
    loadingPrompt() {
      if (!this.hasPassAllChecks)
        return "Please perform Database Duplicate checks before proceeding";
      return "";
    },

    title() {
      return "Review and Update MSDAT Dashboard";
    },
    message() {
      if (!this.hasPassAllChecks) return "";
      return "One final check to make sure everything is all right!";
    },

    preview() {
      return this.filteredData.map((x) => x);
    },
  },
  mounted() {
    if (!this.hasPassAllChecks) {
      this.isApplyingChecks = true;
      return;
    }
  },
  methods: {
    async proceed() {
      try {
        this.$progress.start();
        this.tableLoading = true;
        // eslint-disable-next-line no-unused-vars
        const response = await this.$store.dispatch("data/push");
        this.$nextTick(() => {
          this.isConfirmationDialogVisible = true;
        });
      } catch (error) {
        const statusCode = error?.response?.status || 500;

        // @TODO  update failures with unique failure status
        if (400 === statusCode) {
          const reason = error?.response?.data?.detail || null;
          if (
            reason ===
            "data upload must be database checked before it can be pushed to MSDAT dashboard"
          ) {
            // ReValidate file
            try {
              // Reset
              await this.$store.dispatch("data/cacheDB_CHECK_Response", null);
              // Retry
              await uploadResource.performDuplicatesCheckDB(this.currentRecord.id);
              // set
              await this.$store.dispatch("data/cacheDB_CHECK_Response", []);
              // eslint-disable-next-line no-empty
            } catch (_error) {
              return this.$router
                .push("/data/duplicates-db")
                .then(() => {
                  this.$notify({ message: "Database check required", type: "error", duration: 0 });
                })
                .catch((e) => console.log(e));
            }
          }
        }
        return this.$notify.error("Error pushing file to MSDAT Dashboard");
      } finally {
        this.$progress.done();
        this.tableLoading = false;
      }
    },
    save() {
      this.showProgressPrompt(
        "Save progress",
        "Are you sure you want to save and continue later?",
        {
          cancel: "Continue",
          confirm: "Save and continue later",
          confirmClass: "primary",
          async confirmCallback() {
            this.$store.dispatch("data/storeData");
          },
        }
      );
    },
    getDate() {
      return new DateTime.now().toLocaleString(DateTime.DATE_MED);
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog-content {
  text-align: center;
}
</style>
